import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import FormHeader from '@/components/inspections/formats/format-a/headers/FormHeader.vue';
import ToolDialog from '@/components/inspections/formats/hseq-sso1-f-45/bodies/ToolDialog/ToolDialog.vue';
import EvaluationToolDialog from '@/components/inspections/formats/hseq-sso1-f-45/bodies/EvaluationToolDialog/EvaluationToolDialog.vue';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import textTransform from '@/helpers/textTransform.js';

export default {
	name: 'RegistersCreateHseqSso1F45',
	data: () => ({
		steps: {
			current: 1,
			views: [1],
		},
		headers: [
			{
				text: 'Nombre',
				value: 'name',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Evaluación',
				value: 'evaluation',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Acciones',
				value: 'actions',
				sortable: false,
				align: 'end',
				class: 'primary--text',
			},
		],
		validationErrors: [],
		toolsInspection: [],
		toolDialog: false,
		evaluationToolDialog: false,
		toolEvaluation: null,
		isLoadingData: false,
		isLoadingSave: false,
		isLoadingDraft: false,
	}),
	computed: {
		...mapState('inspection', ['inspection']),
		...mapState('register', ['register']),
		...mapState('security', ['managements', 'costCenters']),

		breadcrumbs: function () {
			return [
				{
					text: 'Inspecciones',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InspectionsList',
					},
				},
				{
					text: this.inspection
						? textTransform(this.inspection.name)
						: this.$route.params.inspectionId,
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InspectionsRegisterList',
						params: { id: this.$route.params.inspectionId },
					},
				},
				{
					text: 'Nuevo registro',
					disabled: true,
					href: '/',
				},
			];
		},
		isCompletedInspectons: function () {
			return this.isCompleteInspection();
		},
	},
	created() {
		this.initialize();
	},
	methods: {
		...mapActions('inspection', ['find', 'cleanInspection']),
		...mapActions('register', ['create', 'findById']),
		...mapActions('tool', ['listTools']),

		textTransform,

		initialize() {
			$EventBus.$emit('showSubNav', false);
			this.isLoadingData = true;
			this.find({
				success: () => {
					this.isLoadingData = false;
				},
				fail: () => {
					this.isLoadingData = false;
				},
				id: this.$route.params?.inspectionId,
			});
			this.listTools();
		},
		validateHeader() {
			let result = true;
			if (this.inspection?.headers && Array.isArray(this.inspection?.headers)) {
				this.inspection.headers.forEach((header) => {
					if (
						!header.value ||
						header.value === undefined ||
						header.value == ''
					) {
						result = false;
					}
				});
			}
			return result;
		},
		addToolsInspections(tools) {
			tools.forEach((tool) => {
				const data = {
					...tool,
					isEvaluated: false,
					hasObservation: false,
					criterias: this.inspection.bodies.items,
					answers: this.inspection.bodies.options_answer,
				};
				const isRepeat = this.toolsInspection.some((tool) => tool.id === data.id);
				if (!isRepeat) {
					this.toolsInspection.push(data);
				}
			});
		},
		removeToolsInspections(id) {
			const index = this.toolsInspection.findIndex((tool) => tool.id === id);
			if (index >= 0) this.toolsInspection.splice(index, 1);
		},
		saveToolEvaluation(tool) {
			this.toolsInspection = this.toolsInspection.map(function (item) {
				return item.id == tool.id ? { ...tool } : item;
			});
		},
		openEvalutionDialog(tool) {
			this.evaluationToolDialog = true;
			this.toolEvaluation = JSON.parse(JSON.stringify(tool));
		},
		saveRegister(status = 'PENDING') {
			if (status == 'PENDING') {
				this.isLoadingSave = true;
			} else {
				this.isLoadingDraft = true;
			}
			try {
				const service_id = this.findValueInArray({
					items: this.inspection?.headers,
					prop: 'key',
					val: 'service',
					propFound: 'value',
				});
				let data = {
					register: {
						inspection_id: this.inspection.id,
						format: this.inspection.format,
						service_id,
						status,
						date: this.findValueInArray({
							items: this.inspection?.headers,
							prop: 'key',
							val: 'date',
							propFound: 'value',
						}),
						manager_id: this.findValueInArray({
							items: this.inspection?.headers,
							prop: 'key',
							val: 'manager',
							propFound: 'value',
						}),
						management_id: this.findValueInArray({
							items: this.costCenters,
							prop: 'id',
							val: service_id,
							propFound: 'management_id',
						}),
						company_id: parseInt(localStorage.getItem('company_id')),
					},
					headers: [],
					tools: [],
				};
				data.headers = this.inspection.headers.map((header) => {
					return {
						header_id: header.id,
						value: (header.value || '').toString() || null,
					};
				});
				data.tools = this.toolsInspection.map((tool) => {
					const bodies = tool.criterias.map((question) => {
						if (question.value && question.value == 'NO') {
							return {
								body_id: question.id,
								value: question.value || null,
								actions: question.actions,
								responsible_id: question.responsible_id,
								date: question.date,
							};
						} else {
							return {
								body_id: question.id,
								value: question.value || null,
							};
						}
					});
					return { tool_id: tool.id, bodies };
				});
				this.sendRegister(data);
			} catch (error) {}
		},
		sendRegister(data) {
			this.validationErrors = [];
			this.create({
				success: () => {
					this.isLoadingSave = false;
					this.isLoadingDraft = false;
					this.$router.push({
						name: 'InspectionsRegisterList',
						params: { id: this.inspection.format },
					});
					$EventBus.$emit(
						'showSnack',
						'success',
						'Se ha registrado satisfactoriamente.'
					);
				},
				fail: (error) => {
					const { response } = error;
					this.isLoadingSave = false;
					this.isLoadingDraft = false;
					if (response && response?.data?.message) {
						this.validationErrors = Array.isArray(response?.data?.message)
							? response?.data?.message
							: [];
					} else {
						this.validationErrors = [
							'Algo salio mal, no se guardo la información.',
						];
					}
				},
				data,
			});
		},
		findValueInArray({ items = [], prop = null, val = null, propFound = null }) {
			let found;
			if (Array.isArray(items) && prop && val) {
				found = items.find((item) => item[prop] == val);
				if (found && propFound) {
					found = found[propFound] ? found[propFound] : null;
				}
			}
			return found || null;
		},
		updateEvaluationStatus() {
			this.toolsInspection = this.toolsInspection.map((tool) => {
				const isEvaluated = !tool.criterias.some((item) => {
					if (item.value == undefined) {
						return true;
					} else if (item.value == 'NO' && !item.actions) {
						return true;
					} else if (item.value == 'NO' && !item.responsible_id) {
						return true;
					} else if (item.value == 'NO' && !item.date) {
						return true;
					} else {
						return false;
					}
				});
				const hasObservation = tool.criterias.some((item) => item?.value == 'NO');
				return { ...tool, hasObservation, isEvaluated };
			});
		},
		isCompleteInspection() {
			const headers = this.inspection?.headers || [];
			const tools = this.toolsInspection || [];
			let incomplete = false;

			headers.forEach((header) => {
				if (!header?.value) {
					incomplete = true;
				}
			});
			if (tools.length > 0) {
				tools.forEach((tool) => {
					tool.criterias.forEach((criteria) => {
						if (!criteria?.value) {
							incomplete = true;
						} else if (criteria?.value == 'NO' && !criteria.actions) {
							incomplete = true;
						} else if (criteria?.value == 'NO' && !criteria.responsible_id) {
							incomplete = true;
						} else if (criteria?.value == 'NO' && !criteria.date) {
							incomplete = true;
						}
					});
				});
			} else {
				incomplete = true;
			}
			return !incomplete;
		},
		isCompletedTools() {
			const tools = this.toolsInspection || [];
			let incomplete = false;
			if (tools.length > 0) {
				tools.forEach((tool) => {
					tool.criterias.forEach((criteria) => {
						if (!criteria?.value) {
							incomplete = true;
						} else if (criteria?.value == 'NO' && !criteria.actions) {
							incomplete = true;
						} else if (criteria?.value == 'NO' && !criteria.responsible_id) {
							incomplete = true;
						} else if (criteria?.value == 'NO' && !criteria.date) {
							incomplete = true;
						}
					});
				});
			} else {
				incomplete = true;
			}
			return !incomplete;
		},
	},
	destroyed() {
		this.cleanInspection();
	},
	components: { FormHeader, ToolDialog, EvaluationToolDialog, ValidationAlert },
};
